<template>
	<PromotionBannerSetsForm @onSubmit="onCreate" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PromotionBannerSetsForm from '@/components/PromotionBannerSetsForm.vue';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'PromotionBannerSetsCreate',
	components: {
		PromotionBannerSetsForm,
	},

	computed: {
		...mapGetters({
			isCreateSuccess: 'promotionBannerSets/isCreateSuccess',
		}),
	},

	methods: {
		...mapActions({
			createPromotionBannerSet: 'promotionBannerSets/createPromotionBannerSet',
		}),

		async onCreate(params) {
			await this.createPromotionBannerSet(params);
			if (this.isCreateSuccess) {
				this.$router.push({ name: ROUTE_NAME.PROMOTION_BANNER_SET_LIST });
			}
		},
	},
};
</script>
